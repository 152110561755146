<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13 8C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8ZM8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7ZM5.13176 13.4961L6 13C5.13176 13.4961 5.13202 13.4966 5.13229 13.4971L5.13286 13.4981L5.13407 13.5002L5.13685 13.505L5.14378 13.5167C5.14902 13.5256 5.15551 13.5363 5.16327 13.5488C5.17877 13.5739 5.19937 13.6061 5.22525 13.6445C5.27694 13.7213 5.35005 13.8232 5.44597 13.9417C5.63731 14.1781 5.92294 14.4847 6.31463 14.7894C7.1072 15.4058 8.31842 16 10 16C11.6816 16 12.8928 15.4058 13.6854 14.7894C14.0771 14.4847 14.3627 14.1781 14.554 13.9417C14.65 13.8232 14.7231 13.7213 14.7747 13.6445C14.8006 13.6061 14.8212 13.5739 14.8367 13.5488C14.8445 13.5363 14.851 13.5256 14.8562 13.5167L14.8632 13.505L14.8659 13.5002L14.8671 13.4981L14.8677 13.4971C14.868 13.4966 14.8682 13.4961 14 13L14.8682 13.4961C15.0451 13.1866 15.0438 12.8064 14.8649 12.4981C14.686 12.1898 14.3565 12 14 12H6C5.64353 12 5.31401 12.1898 5.13509 12.4981C4.95617 12.8064 4.9549 13.1866 5.13176 13.4961Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M10 14C12.8571 14 14 12 14 12H6C6 12 7.14286 14 10 14Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M7.01 7.5V7.51H7V7.5H7.01ZM13.01 7.5V7.51H13V7.5H13.01Z"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
