<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13 8C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8ZM8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7ZM14.8682 13.5039L14 14C14.8682 13.5039 14.868 13.5034 14.8677 13.5029L14.8671 13.5019L14.8659 13.4998L14.8632 13.495L14.8562 13.4833C14.851 13.4744 14.8445 13.4637 14.8367 13.4512C14.8212 13.4261 14.8006 13.3939 14.7747 13.3555C14.7231 13.2787 14.65 13.1768 14.554 13.0583C14.3627 12.8219 14.0771 12.5153 13.6854 12.2106C12.8928 11.5942 11.6816 11 10 11C8.31842 11 7.1072 11.5942 6.31463 12.2106C5.92294 12.5153 5.63731 12.8219 5.44597 13.0583C5.35005 13.1768 5.27694 13.2787 5.22525 13.3555C5.19937 13.3939 5.17877 13.4261 5.16327 13.4512C5.15551 13.4637 5.14902 13.4744 5.14378 13.4833L5.13685 13.495L5.13407 13.4998L5.13286 13.5019L5.13229 13.5029C5.13202 13.5034 5.13176 13.5039 6 14L5.13176 13.5039C4.9549 13.8134 4.95617 14.1936 5.13509 14.5019C5.31401 14.8102 5.64353 15 6 15H14C14.3565 15 14.686 14.8102 14.8649 14.5019C15.0438 14.1936 15.0451 13.8134 14.8682 13.5039Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM2 11C2 6.02944 6.02944 2 11 2V0C4.92487 0 0 4.92487 0 11H2ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2Z"
      fill="currentColor"
    />
    <circle
      cx="14"
      cy="8"
      fill="currentColor"
      r="1"
    />
    <circle
      cx="8"
      cy="8"
      fill="currentColor"
      r="1"
    />
    <path
      d="M11 13C13.8571 13 15 15 15 15L7 15C7 15 8.14286 13 11 13Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
