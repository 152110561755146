<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13.2222 7.55556C13.7745 7.55556 14.2222 7.10784 14.2222 6.55556C14.2222 6.00327 13.7745 5.55556 13.2222 5.55556C12.6699 5.55556 12.2222 6.00327 12.2222 6.55556C12.2222 7.10784 12.6699 7.55556 13.2222 7.55556ZM6.41931 14.9483C6.14317 15.4219 5.53633 15.5852 5.0594 15.3127C4.57989 15.0387 4.41329 14.4278 4.6873 13.9483L5.55554 14.4444C4.6873 13.9483 4.68756 13.9478 4.68784 13.9474L4.68841 13.9464L4.68964 13.9442L4.69248 13.9393L4.6997 13.9271C4.70519 13.9178 4.71205 13.9065 4.72031 13.8931C4.73682 13.8665 4.75895 13.8318 4.78687 13.7904C4.84266 13.7075 4.92196 13.5969 5.02631 13.468C5.23452 13.2108 5.54594 12.8764 5.97335 12.544C6.83735 11.872 8.15968 11.2222 9.99999 11.2222C11.8403 11.2222 13.1626 11.872 14.0266 12.544C14.454 12.8764 14.7655 13.2108 14.9737 13.468C15.078 13.5969 15.1573 13.7075 15.2131 13.7904C15.241 13.8318 15.2632 13.8665 15.2797 13.8931C15.2879 13.9065 15.2948 13.9178 15.3003 13.9271L15.3075 13.9393L15.3103 13.9442L15.3116 13.9464L15.3121 13.9474C15.3124 13.9478 15.3127 13.9483 14.459 14.4361L15.3127 13.9483C15.5867 14.4278 15.4201 15.0387 14.9406 15.3127C14.4636 15.5852 13.8568 15.4219 13.5807 14.9483L13.5791 14.9458C13.5755 14.94 13.5672 14.9269 13.5542 14.9076C13.5282 14.8689 13.4835 14.8058 13.4192 14.7264C13.2901 14.567 13.0856 14.3458 12.7987 14.1227C12.2342 13.6836 11.3343 13.2222 9.99999 13.2222C8.66569 13.2222 7.7658 13.6836 7.20123 14.1227C6.91435 14.3458 6.7099 14.567 6.5808 14.7264C6.51651 14.8058 6.4718 14.8689 6.44574 14.9076C6.43274 14.9269 6.42448 14.94 6.42084 14.9458L6.41931 14.9483L6.41931 14.9483ZM6.55554 7.55556C7.10783 7.55556 7.55554 7.10784 7.55554 6.55556C7.55554 6.00327 7.10783 5.55556 6.55554 5.55556C6.00326 5.55556 5.55554 6.00327 5.55554 6.55556C5.55554 7.10784 6.00326 7.55556 6.55554 7.55556Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M7.00998 8V8.01H7V8H7.00998ZM13 8V8.01H12.99V8H13Z"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        d="M6 14C6.40537 13.3918 6.98845 12.8868 7.69061 12.5357C8.39277 12.1846 9.18927 11.9998 10 12C11.7085 12 13.2012 12.804 14 14"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
