<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM13.2222 7.55566C13.7745 7.55566 14.2222 7.10795 14.2222 6.55566C14.2222 6.00338 13.7745 5.55566 13.2222 5.55566C12.6699 5.55566 12.2222 6.00338 12.2222 6.55566C12.2222 7.10795 12.6699 7.55566 13.2222 7.55566ZM13.5807 11.7183C13.8568 11.2448 14.4637 11.0815 14.9406 11.354C15.4201 11.628 15.5867 12.2388 15.3127 12.7184L14.4445 12.2222C15.3127 12.7184 15.3124 12.7188 15.3122 12.7193L15.3116 12.7203L15.3104 12.7224L15.3075 12.7273L15.3003 12.7396C15.2948 12.7488 15.2879 12.7602 15.2797 12.7735C15.2632 12.8002 15.2411 12.8348 15.2131 12.8763C15.1573 12.9591 15.078 13.0697 14.9737 13.1986C14.7655 13.4558 14.4541 13.7903 14.0267 14.1227C13.1627 14.7947 11.8403 15.4444 10 15.4444C8.1597 15.4444 6.83738 14.7947 5.97337 14.1227C5.54597 13.7903 5.23455 13.4558 5.02634 13.1986C4.92198 13.0697 4.84268 12.9591 4.7869 12.8763C4.75897 12.8348 4.73685 12.8002 4.72034 12.7735C4.71208 12.7602 4.70522 12.7488 4.69973 12.7396L4.69251 12.7273L4.68966 12.7224L4.68843 12.7203L4.68786 12.7193C4.68759 12.7188 4.68733 12.7184 5.54099 12.2306L4.68733 12.7184C4.41332 12.2388 4.57991 11.628 5.05943 11.354C5.53635 11.0815 6.1432 11.2448 6.41934 11.7183L6.42087 11.7208C6.42451 11.7267 6.43277 11.7398 6.44577 11.7591C6.47183 11.7978 6.51653 11.8608 6.58083 11.9402C6.70992 12.0997 6.91437 12.3209 7.20126 12.544C7.76583 12.9831 8.66572 13.4444 10 13.4444C11.3343 13.4444 12.2342 12.9831 12.7988 12.544C13.0857 12.3209 13.2901 12.0997 13.4192 11.9402C13.4835 11.8608 13.5282 11.7978 13.5543 11.7591C13.5673 11.7398 13.5755 11.7267 13.5792 11.7208L13.5807 11.7183L13.5807 11.7183ZM6.55554 7.55566C7.10783 7.55566 7.55554 7.10795 7.55554 6.55566C7.55554 6.00338 7.10783 5.55566 6.55554 5.55566C6.00326 5.55566 5.55554 6.00338 5.55554 6.55566C5.55554 7.10795 6.00326 7.55566 6.55554 7.55566Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Ellipse 119"
      cx="10"
      cy="10"
      r="9"
      stroke="currentColor"
      stroke-width="2"
    />
    <circle
      id="Ellipse 118"
      cx="13"
      cy="7"
      fill="currentColor"
      r="0.5"
      stroke="currentColor"
    />
    <path
      id="Vector"
      d="M6 12C6 12 7.14286 14 10 14C12.8571 14 14 12 14 12"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <circle
      id="Ellipse 119_2"
      cx="7"
      cy="7"
      fill="currentColor"
      r="0.5"
      stroke="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
